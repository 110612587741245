<template>
  <div class="page">
    <div class="page-main">
      <el-form class="page-card" ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item :label="$t('firmware.uploadFile')" prop="uploadFile" class="form-item-upload-file">
          <div class="form-item-width">
            <el-upload class="upload" drag ref="upload" action="/tb-firmware/upload" name="multipartFile"
              :headers="{ 'Accept-Language': language == 'en' ? 'en-US' : language }" :before-upload="beforeUpload"
              :on-change="onChange" :file-list="form.uploadFile" :on-remove="onRemove" :on-success="onSuccess">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text" style="font-size: 16px">
                {{ $t('components.upload.tip1') }}
                <em>{{ $t('components.upload.tip2') }}</em>
              </div>
              <div class="el-upload__tip" slot="tip" v-html="$t('components.upload.tip3', ['.zip', '300M'])"></div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item :label="$t('firmware.firmwareName')" prop="firmwareName">
          <el-input v-model="form.firmwareName" :placeholder="$t('firmware.firmwareNamePla')" :maxlength="50">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('firmware.versionNumber')" prop="firmwareVersion">
          <el-input v-model="form.firmwareVersion" :placeholder="$t('firmware.versionNumberPla')" :maxlength="50">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('firmware.supportModel')" prop="supportModel">
          <select-model v-model="form.supportModel" :placeholder="$t('firmware.supportModelRule')" multiple
            :defaultSelectAll="false"></select-model>
        </el-form-item>
        <el-form-item :label="$t('ssite.site')" prop="siteIds">
          <select-site v-model="form.siteIds" checkboxShow checkStrictly></select-site>
        </el-form-item>
        <el-form-item :label="$t('firmware.description')">
          <el-input v-model="form.firmwareDesc" type="textarea" :rows="4"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="footer-btns">
            <el-button class="footer-btn" type="primary" plain @click="cancel">{{ $t('cancel') }}</el-button>
            <el-button class="footer-btn" type="primary" @click="submit"
              :disabled="!uploadFileState || !goodZipContent">{{ $t('save') }}</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { firmwareVersionIsRepeat, frimwareQuery, addFrimware } from '@/api/firmware'
import { SelectSite, SelectModel } from '@/components'
import { fileCheck } from '@/plugins/methods'
import { isGoodZipContent } from '@/plugins/methods'

export default {
  name: 'CustomerFirmwareDetails',
  components: {
    SelectSite,
    SelectModel,
  },
  data() {
    var uploadFileValidate = (rule, value, callback) => {
      if (this.uploadFileState) {
        callback()
      } else {
        callback(new Error(this.$t('firmware.uploadFileValidate')))
      }
    }
    return {
      goodZipContent: false,
      loading: false,
      uploadFileState: true,
      uploading: false,
      form: {
        uploadFile: [],
        uploadFileUrl: '',
        firmwareName: '',
        firmwareVersion: '',
        supportModel: [],
        firmwareDesc: '',
        siteIds: [],
        firmwareSize: '',
        identify: '',
      },
      rules: {
        uploadFile: [
          {
            required: true,
            message: this.$t('firmware.uploadFileRule'),
            trigger: 'change',
          },
          { validator: uploadFileValidate, trigger: 'blur' },
        ],
        firmwareName: {
          required: true,
          message: this.$t('firmware.firmwareNamePla'),
          trigger: 'blur',
        },
        siteIds: {
          required: true,
          message: this.$t('firmware.firmwareSite'),
          trigger: 'change',
        },
        firmwareVersion: [
          {
            required: true,
            message: this.$t('firmware.versionNumberPla'),
            trigger: 'blur',
          },
          {
            message: this.$t('firmware.versionNumberExists'),
            validator: async (rule, value, callback) => {
              if (value && this.form.supportModel) {
                var p = {
                  id: this.$route.query.id || '',
                  firmwareVersion: value,
                  supportModel: this.form.supportModel,
                }
                await firmwareVersionIsRepeat(p).then(({ data }) => {
                  if (data.result.rows[0]) {
                    callback(new Error(this.$t('firmware.firmwareVersionRule')))
                  }
                })
              }
            },
            trigger: 'blur',
          },
        ],
        supportModel: [
          {
            required: true,
            message: this.$t('firmware.supportModelRule'),
            trigger: 'change',
          },
        ],
      },
      groupTypeList: [],
    }
  },
  computed: {
    ...mapState('memory', ['language'])
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
    this.$route.query.id ? this.goodZipContent = true :''
  },
  // watch: {
  //   uploadFileState(val) {
  //     this.$store.commit('app/SET_CHANGE_LANGUAGE', {
  //       value: val,
  //       message: val ? '' : this.$t('firmware.uploadFileValidate'),
  //     })
  //   },
  // },
  beforeRouteLeave(to, from, next) {
    if (!this.uploading || to.params.type == 'cancel') {
      next()
    } else {
      this.$message.error(this.$t('firmware.uploadFileValidate'))
    }
  },
  methods: {
    getData(id) {
      frimwareQuery({ id })
        .then((res) => {
          this.form = res.data.result.rows[0]
          this.form.uploadFileUrl = res.data.result.rows[0].uploadFileUrl
          const fileUrl = this.form.uploadFileName
          this.$set(this.form, 'uploadFile', [
            { name: fileUrl.slice(fileUrl.lastIndexOf('\\') + 1) },
          ])
          this.form.supportModel = this.form.supportModel.split(',')
        })
        .catch(() => { })
    },
    cancel() {
      this.$router.push({ name: 'firmware', params: { activeName: 'customerFirmware', type: 'cancel' } })
    },
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            firmwareType: 1,
            uploadFileUrl: this.form.uploadFileUrl,
            firmwareName: this.form.firmwareName,
            firmwareVersion: this.form.firmwareVersion,
            supportModel: this.form.supportModel.join(','),
            firmwareDesc: this.form.firmwareDesc,
            siteIds: this.form.siteIds,
            firmwareSize: this.form.firmwareSize,
            identify: this.form.identify,
          }
          this.form.id && (params.id = this.form.id)
          addFrimware(params)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.cancel()
            })
            .catch(() => { })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 校验文件格式，如果符合则自动填写表单
    checkformat(name) {
      let nameFormat = /^[a-z0-9A-Z]*-(M357|M357s|H3X6|M8|H3X6W|H2|H2P|M3|M5|M7|H3|H3P|H3G|H6|M3s|M5s|M7s|M7sPro)_[a-z0-9A-Z\\.]*\.zip/ //CRTXEDS-951, XSH
      if (nameFormat.test(name)) {
        let textOne = name.split('-')
        let textTwo = textOne[1].split('_')
        this.form.firmwareVersion = textTwo[1].split('.zip')[0]
        if (textTwo[0] == 'M357') {
          this.form.firmwareName = 'M3/M5/M7'
          this.form.supportModel = ['M3', 'M5', 'M7']
        } else if (textTwo[0] == 'M357s') {
          this.form.firmwareName = 'M3s/M5s/M7s/M7sPro'
          this.form.supportModel = ['M3s', 'M5s', 'M7s', 'M7s Pro']
        } else if(textTwo[0] == 'M3') {
          this.form.firmwareName = 'M3'
          this.form.supportModel = ['M3']
        } else if(textTwo[0] == 'M5') {
          this.form.firmwareName = 'M5'
          this.form.supportModel = ['M5']
        } else if(textTwo[0] == 'M7') {
          this.form.firmwareName = 'M7'
          this.form.supportModel = ['M7']
        } else if(textTwo[0] == 'M3s') {
          this.form.firmwareName = 'M3s'
          this.form.supportModel = ['M3s']
        } else if(textTwo[0] == 'M5s') {
          this.form.firmwareName = 'M5s'
          this.form.supportModel = ['M5s']
        } else if(textTwo[0] == 'M7s') {
          this.form.firmwareName = 'M7s'
          this.form.supportModel = ['M7s']
        } else if(textTwo[0] == 'M7sPro') {
          this.form.firmwareName = 'M7sPro'
          this.form.supportModel = ['M7s Pro']
        } else if (textTwo[0] == 'H3X6') {
          this.form.firmwareName = 'H3P/H3G/H6'
          this.form.supportModel = ['H3P', 'H3G', 'H6']
        } else if (textTwo[0] == 'H3') {
          this.form.firmwareName = 'H3P/H3G'
          this.form.supportModel = ['H3P', 'H3G']
        } else if (textTwo[0] == 'H3G') {
          this.form.firmwareName = 'H3G'
          this.form.supportModel = ['H3G']
        } else if (textTwo[0] == 'H3P') {
          this.form.firmwareName = 'H3P'
          this.form.supportModel = ['H3P']
        } else if (textTwo[0] == 'H6') {
          this.form.firmwareName = 'H6'
          this.form.supportModel = ['H6']
        } else if (textTwo[0] == 'M8') {
          this.form.firmwareName = 'M8'
          this.form.supportModel = ['M8']
        } else if (textTwo[0] == 'H3X6W') {
          this.form.firmwareName = 'H3W/H6W'
          this.form.supportModel = ['H3W', 'H6W']
        } else if (textTwo[0] == 'H2P') {
          this.form.firmwareName = 'H2P'
          this.form.supportModel = ['H2P']
        } else if (textTwo[0] == 'H2') {
            this.form.firmwareName = 'H2'
            this.form.supportModel = ['H2']
        }
      } else if (name.startsWith('H2P')) {
          this.form.firmwareName = 'H2P'
          this.form.supportModel = ['H2P']
        } else if (name.startsWith('H2')) {
            this.form.firmwareName = 'H2'
            this.form.supportModel = ['H2']
        }
    },
    async beforeUpload(file) {
      this.checkformat(file.name)
      this.uploadFileState = false
      this.$refs.form.validateField('uploadFile')
      const goodFileType = fileCheck({
        file,
        fileTypes: ['.zip'],
        fileSize: 3 * 100 * 1024 * 1024,
        callback: (typeErr, sizeErr) => {
          typeErr && this.$message.error(this.$t('components.upload.typeTipsMsg', ['.zip']))
          !typeErr &&
            sizeErr &&
            this.$message.error(this.$t('components.upload.sizeTipsMsg', ['300M']))
        },
      })
      this.goodZipContent = await isGoodZipContent(file);
      if (!this.goodZipContent) {
        this.$message.error(this.$t('edm023'))
      }
      if (goodFileType && this.goodZipContent) {
        this.uploadFileState = false
        this.uploading = true
        return Promise.resolve();
      }else{
        return Promise.reject();
      }
    },
    onChange(file, fileList) {
      this.form.uploadFile = [fileList[fileList.length - 1]]
    },
    onRemove(file, fileList) {
      this.uploadFileState = false
      this.form.uploadFile = fileList
      this.uploading = false
      this.$refs.form.validateField('uploadFile')
      this.$refs.upload.abort()
    },
    onSuccess(response) {
      if (response.code === 10000) {
        this.$message.error(response.message)
        this.form.uploadFile = []
        this.uploadFileState = false
      } else {
        this.form.firmwareSize = response.result.rows[0].fileSize
        this.form.uploadFileUrl = response.result.rows[0].uploadUrl
        this.form.identify = +response.result.rows[0].identify
        this.uploadFileState = true
      }
      this.uploading = false
      this.$refs.form.validateField('uploadFile')
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;

.page-main {
  padding: 30px;
}

.upload {

  ::v-deep.el-upload,
  ::v-deep.el-upload-dragger {
    width: 100%;
  }

  ::v-deep.el-list-enter-active,
  ::v-deep.el-list-leave-active {
    transition: none;
  }

  ::v-deep.el-list-enter,
  ::v-deep.el-list-leave-active {
    opacity: 0;
  }

  ::v-deep.el-upload-list {
    height: 40px;
  }

  ::v-deep.el-progress__text {
    display: none;
  }

  .form-item-upload-file ::v-deep.el-form-item__error {
    position: absolute;
    top: 182px;
  }

  .el-upload__tip {
    font-size: 16px;
  }
}
</style>
